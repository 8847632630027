@import url(https://fonts.googleapis.com/css?family=Prompt:300&display=swap);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Prompt', sans-serif;
  --ion-color-primary: #4cc5c5;
  --ion-color-primary-rgb: 76,197,197;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #43adad;
  --ion-color-primary-tint: #5ecbcb;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
.header-md:after {
  background-image: none;
}
ion-image.img-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ReactCollapse--collapse {
  margin-left: 16px;
  -webkit-transition: height 500ms;
  transition: height 500ms;
  font-size: 14px;
}

ion-content {
  --background: transparent; }

.manow-header {
  --padding-bottom: 0;
  --border-color:#ffff; }

.manow-header ion-buttons {
  align-self: flex-start; }

.manow-title {
  font-size: 25px;
  align-items: flex-end;
  justify-content: flex-start; }

.manow-logo {
  align-self: start; }

.manow-right-buttons {
  align-self: start;
  margin-top: 20px; }

.manow-modal {
  --width: 80%;
  --height: 80%;
  --border-radius: 10px; }

.manow-input {
  padding: 8px 0px !important;
  text-align: left; }

.manow-input input {
  border: 1px solid var(--ion-color-light) !important;
  border-radius: 10px;
  padding-left: 20px !important; }

.menu-content-open:not(#fakeId) {
  pointer-events: initial; }

