ion-content {
  --background: transparent;
}
.manow-header {
  --padding-bottom: 0;
  --border-color:#ffff;
  // --background: top / 100% 150% url('../assets/header.png') no-repeat;
  // --min-height: 10px;
  // --border-style: none;
}
.manow-header {
  ion-buttons {
    align-self: flex-start;
  }
}
.manow-title {
  // padding-top: 80px;
  font-size: 25px;
  align-items: flex-end;
  justify-content: flex-start;
}
.manow-logo {
  align-self: start;
}
.manow-right-buttons {
  align-self: start;
  margin-top: 20px;
}
.manow-modal {
  --width: 80%;
  --height: 80%;
  --border-radius: 10px;
}
.manow-input {
  padding: 8px 0px !important;
  text-align: left;
}
.manow-input input{
  border: 1px solid var(--ion-color-light) !important;
  border-radius: 10px;
  padding-left: 20px !important;
}
.menu-content-open:not(#fakeId) {
  pointer-events: initial
}