.header-md:after {
  background-image: none;
}
ion-image.img-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ReactCollapse--collapse {
  margin-left: 16px;
  transition: height 500ms;
  font-size: 14px;
}
